/* ChatInput.css */
:root {
  --tagify-dd-item-pad: 0.5em 0.7em;
  --tagify-dd-bg-color: #333;
  --tagify-dd-text-color: #fff;
}

.tagify {
  width: 100%; /* Ensures the input fills the available width */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  text-align: left;
  max-height: 25vh;
  overflow-y: auto;
  --tag-bg: transparent;
  --tag-hover: transparent;
  --tag-remove-btn-color: white;
  --tag-remove-btn-bg--hover: black;
  --tag-remove-bg: var(--tag-hover);
  --tag-text-color: white;
  --tags-border-color: None;
  --tags-focus-border-color: None;
  --tags-hover-border-color: None;
  --tags-disabled-bg: transparent;
  --placeholder-color: rgb(255, 255, 255, 0.5);
  --placeholder-color-focus: rgb(255, 255, 255, 0.5);
}

.tagify __tag__removeBtn {
  color: #fff;
}

.tagify__tag-text {
  font-weight: bolder;
  color: #4593f8;
}

.tagify__dropdown .tagify__dropdown__item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 1em;
  grid-template-areas:
    "avatar name"
    "avatar path";
}

.tagify__dropdown header.tagify__dropdown__item {
  grid-template-areas:
    "add remove-tags"
    "remaning .";
}

.tagify__dropdown
  .tagify__dropdown__item:hover
  .tagify__dropdown__item__avatar-wrap {
  transform: scale(1.2);
}

.tagify__dropdown .tagify__dropdown__item__avatar-wrap {
  grid-area: avatar;
  width: 36px;
  height: 36px;
  overflow: hidden;
  transition: 0.1s ease-out;
  display: flex; /* Add flexbox */
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}

.tagify__dropdown img {
  width: 100%;
  align-items: center;
}

.tagify__dropdown header.tagify__dropdown__item > div,
.tagify__dropdown .tagify__dropdown__item strong {
  grid-area: name;
  width: 100%;
  align-self: center;
}

.tagify__dropdown span {
  grid-area: path;
  width: 100%;
  font-size: 0.9em;
  opacity: 0.6;
}

.tagify__dropdown .tagify__dropdown__item__addAll {
  border-bottom: 1px solid #ddd;
  gap: 0;
}

.tagify__dropdown .remove-all-tags {
  grid-area: remove-tags;
  justify-self: self-end;
  font-size: 0.8em;
  padding: 0.2em 0.3em;
  border-radius: 3px;
  user-select: none;
}

.tagify__dropdown .remove-all-tags:hover {
  color: white;
  background: salmon;
}
