.prose-content {
  color: #fff;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  overflow-y: auto;
}

.prose-content code {
  color: #fff;
  background-color: #666;
  font-family: Ubuntu Sans Mono;
}

.prose-content button {
  background-color: #000000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.save-button {
  margin-right: 5px;
  font-family: Ubuntu Sans Mono;
  color: #090C13;
  background-color: #D6D7D8;
  padding: 5px 10px;
}

.save-button:hover {
  background-color: #666;
  color: #fff;
  cursor: pointer;
}

.save-button:disabled {
  background-color: #666;
  color: #fff;
  cursor: not-allowed;
}

@import url("@radix-ui/colors/tomato-dark.css");
@import url("@radix-ui/colors/mauve-dark.css");
@import url("@radix-ui/colors/cyan-dark.css");
@import url("@radix-ui/colors/grass-dark.css");
@import url("@radix-ui/colors/amber-dark.css");
@import url("@radix-ui/colors/red-dark.css");

.dark-editor {
  --accentBase: var(--tomato-1);
  --accentBgSubtle: var(--tomato-2);
  --accentBg: var(--tomato-3);
  --accentBgHover: var(--tomato-4);
  --accentBgActive: var(--tomato-5);
  --accentLine: var(--tomato-6);
  --accentBorder: var(--tomato-7);
  --accentBorderHover: var(--tomato-8);
  --accentSolid: var(--tomato-9);
  --accentSolidHover: var(--tomato-10);
  --accentText: var(--tomato-11);
  --accentTextContrast: var(--tomato-12);

  --baseBase: var(--mauve-1);
  --baseBgSubtle: var(--mauve-2);
  --baseBg: var(--mauve-3);
  --baseBgHover: var(--mauve-4);
  --baseBgActive: var(--mauve-5);
  --baseLine: var(--mauve-6);
  --baseBorder: var(--mauve-7);
  --baseBorderHover: var(--mauve-8);
  --baseSolid: var(--mauve-9);
  --baseSolidHover: var(--mauve-10);
  --baseText: var(--mauve-11);
  --baseTextContrast: var(--mauve-12);

  --admonitionTipBg: var(--cyan-4);
  --admonitionTipBorder: var(--cyan-8);

  --admonitionInfoBg: var(--grass-4);
  --admonitionInfoBorder: var(--grass-8);

  --admonitionCautionBg: var(--amber-4);
  --admonitionCautionBorder: var(--amber-8);

  --admonitionDangerBg: var(--red-4);
  --admonitionDangerBorder: var(--red-8);

  --admonitionNoteBg: var(--mauve-4);
  --admonitionNoteBorder: var(--mauve-8);

  font-family: "Ubuntu Sans Mono";
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  color: var(--baseText);
  --basePageBg: #000000;

  --editor-content-padding: 24px;

  & [data-lexical-editor] {
    padding: 0 var(--editor-content-padding);
    border-radius: 10px;
    transition: background 0.2s ease;
  }

  & .toolbar {
    padding: 0 var(--editor-content-padding);
  }

  & .mdxeditor-source-editor {
    border-radius: 10px;
    height: calc(100vh - 80px);
    overflow-y: auto;
  }

  & [data-lexical-editor] {
    background: #161B22;
  }

  & [data-lexical-editor]:focus-within {
    background: #282c34;
  }

  & .cm-editor {
    background: #161B22;
    color: #666;
    border-radius: 10px;
  }

  & .cm-gutters {
    color: inherit
  }
}

/* Target the tooltip content */
[data-radix-popper-content-wrapper] > div {
  background-color: #090C13 !important;
  color: #fff !important;
}

/* Animation for tooltip appearance */
[data-radix-popper-content-wrapper][data-state="delayed-open"],
[data-radix-popper-content-wrapper][data-state="instant-open"] {
  animation: slideDownAndFade 0.2s ease-out;
}
